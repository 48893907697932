/* eslint default-param-last: 0 */
import { GENERAL_ERROR } from '../actions/error';

const errorReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GENERAL_ERROR: {
      return {
        ...state,
        fatalError: { error: action.payload.error.message }
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default errorReducer;
