import axios from 'axios';

export const httpClient = (function client() {
  const apiPath = `${process.env.REACT_APP_API_BASE_URL || ''}/api/`;
  const instance = axios.create({
    baseURL: apiPath
  });

  return instance;
}());

export default httpClient;
