import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ProgressSpinner from '../../components/ProgressSpinner';

const CleverOauthComponent = ({
  exchangeTokens,
}) => {
  const { search } = useLocation();

  useEffect(() => {
    const parsedSearch = new URLSearchParams(search);
    exchangeTokens(parsedSearch.get('code'));
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>Please Wait</h1>
      <ProgressSpinner />
      <p>Please wait while we log you in.</p>
    </div>
  );
};

export default CleverOauthComponent;
