import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';

import './Login.css';
import EapButton from '../../components/EapButton';
import EapStaticAlert from '../../components/EapStaticAlert';
import PartnershipBanner from './PartnershipBanner';
import InviteValidator from '../../components/InviteValidator';
import InlineValidationMessage from '../../components/InlineValidationMessage';

import CleverButton from '../../images/partners/CleverButton.png';
import CleverButtonBlue from '../../images/partners/CleverButtonBlue.png';

const eapUrl = require('../../utils/url-parser');

const CleverID = 'fce3df948b4db5a58220';

class Login extends Component {
  constructor(props) {
    super(props);
    let redirectUrl;
    this.showProgressButton = this.showProgressButton.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.submit = this.submit.bind(this);
    this.errorMessageRef = React.createRef();
    if (!_.isEmpty(props.location.search)) {
      const url = eapUrl.parseRedirectUrl(props.location.search);
      if (eapUrl.isWhitelistedRedirectUrl(url)) {
        redirectUrl = url;
      }
    }

    this.state = {
      loginRequestFired: false,
      redirectUrl,
      username: '',
      password: '',
      cleverLogo: CleverButton,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loginRejected === true) {
      this.setState({
        loginRequestFired: false,
        errorMessage: nextProps.loginRejected ? 'Incorrect username or password' : ''
      }, () => this.errorMessageRef.current.focus());
    }
  }
  
  onTextChange() {
    if (this.props.loginRejected) {
      this.setState({ errorMessage: '' });
    }
  }

  onUsernameChange(e) {
    const { value } = e.target;
    let message = validator.validateSingleInput('username', value);
    if (message) {
      [message] = message; // eslint this is not clearer than message = message[0]
    }
    this.setState({ username: value, usernameError: message }, () => {
      this.onTextChange(e);
    });
  }

  showProgressButton() {
    this.setState({ loginRequestFired: true });
  }

  submit(e) {
    e.preventDefault();
    if (_.isEmpty(this.state.username) || _.isEmpty(this.state.password)) {
      this.setState({ errorMessage: 'You must provide a valid username and password' });
    } else {
      this.showProgressButton();
      const executeLogin = this.props.onLoginClick;
      setTimeout(() => {
        executeLogin(this.state.username, this.state.password, this.state.redirectUrl);
      }, 500);
    }
  }

  render() {
    let showAlert = '';
    if (!_.isEmpty(this.state.errorMessage)) {
      showAlert = (
        <EapStaticAlert>
          <span tabIndex="-1" ref={this.errorMessageRef}>{ this.state.errorMessage }</span>
        </EapStaticAlert>
      );
    }
    let usernameError = '';
    if (this.state.usernameError) {
      usernameError = <InlineValidationMessage message={this.state.usernameError} />;
    }
    return (
      <main className="flex-column login-container" role="main">
        <div className="pure-g login-page pure-u-1-1">
          <div className="login-form">
            <form
              className="pure-form pure-form-stacked eap-grey-form"
              onSubmit={this.submit}
            >
              <InviteValidator location={this.props.location} />
              {showAlert}
              <label htmlFor="username-input" className="">
                USERNAME or EMAIL
              </label>
              <input
                type="text"
                autoComplete="username"
                className="pure-input-1 text-left"
                id="username-input"
                onChange={this.onUsernameChange}
              />
              { usernameError }
              <label htmlFor="password-input" className="">
                PASSWORD
              </label>
              <input
                disabled={this.state.loginRequestFired}
                type="password"
                autoComplete="current-password"
                className="pure-input-1"
                id="password-input"
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                  this.onTextChange(e);
                }}
              />
              <div className="pure-u-1 signin-button-row">
                <EapButton
                  id="button-sign-in"
                  isSubmit
                  text="SIGN IN"
                  disabled={this.state.loginRequestFired}
                  inProgress={this.state.loginRequestFired}
                />
              </div>
              <div className="pure-u-1-3 forgot-password-link">
                <Link
                  to="/reset-password"
                >
                  Forgot password?
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="login-alternative">
          <label
            className="login-alternative-content"
            htmlFor="signupButton"
          >
          DON&#39;T HAVE AN ACCOUNT?
          </label>
          <div className="login-alternative-content">
            <Link
              id="signupButton"
              to={`/signup${this.state.redirectUrl ? this.props.location.search : ''}`}
            >
              <EapButton
                isSecondary
                text="SIGN UP HERE"
              />
            </Link>
          </div>
          <div
            className="pure-u-1 signin-button-row"
            style={{
              marginTop: '0.5rem'

            }}
          >
            <a
              href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(`${window.location.origin}/clever-callback`)}&client_id=${CleverID}`}
              onMouseEnter={() => {
                this.setState({ cleverLogo: CleverButtonBlue });
              }}
              onMouseLeave={() => {
                this.setState({ cleverLogo: CleverButton });
              }}
            >
              <img
                width="140"
                hight="16"
                src={this.state.cleverLogo}
                alt="Sign in with clever"
              />
            </a>
          </div>
          {
            window.location.host.startsWith('pstcc') && <PartnershipBanner />
          }
        </div>
      </main>
    );
  }
}

Login.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  onLoginClick: PropTypes.func.isRequired,
  loginRejected: PropTypes.bool
};

Login.defaultProps = {
  loginRejected: false
};

export default Login;
