import React from 'react';
import PropTypes from 'prop-types';
import './alert.css';
import './EapStaticAlert.css';

// 1. Only displays if a message is valid => not undefined && not empty string
// 2. Error by default. Info if (positive).
const EapStaticAlert = ({
  children,
  positive,
  warning,
  ...rest
}) => {
  const shellStyle = 'eap-static-alert';
  const basicStyle = 'eap-alert-box';
  const additiveStyle = positive ? 'eap-alert-success' : 'eap-alert-error';
  const warningStyle = warning ? 'eap-alert-warning' : '';

  return (
    <div className={shellStyle + (children ? ` ${basicStyle} ${additiveStyle} ${warningStyle}` : '')} {...rest}>
      {children}
    </div>
  );
};

EapStaticAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  positive: PropTypes.bool,
  warning: PropTypes.bool
};

EapStaticAlert.defaultProps = {
  children: undefined,
  positive: false,
  warning: false
};

export default EapStaticAlert;
