import React from 'react';
import PropTypes from 'prop-types';
import EapStaticAlert from './EapStaticAlert';

const GeneralError = ({ reloadAllTheThings }) => (
  <div
    style={{
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <EapStaticAlert
      style={{
        width: '98.5%'
      }}
    >
      <h1>General Error</h1>
    </EapStaticAlert>
    <p>A general error occurred.Contact EdITS support at this email support@edits.net or&nbsp;
      <a
        href="/"
        onClick={() => reloadAllTheThings()}
      >
        return to the home page.
      </a>
    </p>
  </div>
);

GeneralError.propTypes = {
  reloadAllTheThings: PropTypes.func.isRequired
};
export default GeneralError;
